import React from "react";
import Helmet from "react-helmet";
import Layout from "../layout";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import PageHeader from "../components/PageHeader/PageHeader";
import JoinUsForm from "../components/JoinUsForm/JoinUsForm";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";

class WorkPage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet title={`Work | ${config.siteTitle}`} />
        <SEO />
        <Header />
        <PageHeader
          title="Find your place at Toptive"
          content="We build, collaborate, consult, and spend time together outside of work, too. Ready to join us? Find an opening below and apply today."
          backUrl="/team"
        />
        <JoinUsForm />
        <Footer />
      </Layout>
    );
  }
}

export default WorkPage;
