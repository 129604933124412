import React, { Component } from "react";
import { Link } from "gatsby";

class JoinUsForm extends Component {
  render() {
    return (
      <section className="container-fluid bg-gray">
        <div className="container py-5">
          <div className="row">
            <div className="col-12 col-sm-10 offset-sm-1 col-xl-8 offset-xl-2">
              <div className="row my-5">
                <div className="col-12 text-center mb-4">
                  <h1 className="font-weight-bold uppercase color-capry mb-0">
                    Send us an email to <a href="mailto:jobs@toptive.co">jobs@toptive.co</a>
                  </h1>
                  <h2 className="pt-3 mt-4 text-center">We will be in contact as soon as possible</h2>

                  <div className="mt-5">
                    <Link to="/">
                      <button type="button" className="btn btn-capry">Go Back</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default JoinUsForm;
